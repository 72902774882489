
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import useAuth from '@/store/useAuth'

export default {
  setup(): void {
    const route = useRoute()
    const { verifyEmail } = useAuth()
    onMounted(() => {
      verifyEmail(route.query.path as string)
    })
  },
}
