
import { defineComponent, reactive } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength } from '@vuelidate/validators'
import AppInput from '@/components/AppInput.vue'
import AppButton from '@/components/AppButton.vue'
import FormCard from '@/views/auth/FormCard.vue'
import useAuth from '@/store/useAuth'

export default defineComponent({
  components: {
    AppButton,
    AppInput,
    FormCard,
  },
  setup() {
    const { register, isLoading } = useAuth()
    const form = reactive({
      password: '',
      email: '',
      firstName: '',
      lastName: '',
    })
    const rules = {
      password: { required, minLength: minLength(6) },
      email: { required, email },
      firstName: { required },
      lastName: { required },
    }

    const v$ = useVuelidate(rules, form, { $lazy: true })

    const submit = async () => {
      v$.value.$touch()
      if (v$.value.$error) return
      await register(form)
    }

    return {
      v$,
      isLoading,
      submit,
      form,
    }
  },
})
