
import { defineComponent, toRefs } from 'vue'
import useBoards from '@/store/useBoards'
import BoardCard from './components/BoardCard.vue'
import CreateBoard from './components/CreateBoard.vue'
export default defineComponent({
  components: {
    BoardCard,
    CreateBoard,
  },
  setup() {
    const { getBoards, state, ownerBoards, memberBoards } = useBoards()
    const { boards } = toRefs(state)

    getBoards()

    return {
      memberBoards,
      ownerBoards,
      boards,
    }
  },
})
