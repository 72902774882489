
import AppHeader from '@/components/AppHeader.vue'
import useAuth from '@/store/useAuth'
export default {
  components: {
    AppHeader,
  },
  setup() {
    const { me, isAuthenticated } = useAuth()

    console.log('test deploy')

    if (isAuthenticated.value) me()
  },
}
