
import { defineComponent, toRefs, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import useBoards from '@/store/useBoards'
import useLists from '@/store/useLists'
import useCards from '@/store/useCards'
import draggable from 'vuedraggable'
import List from './List.vue'
import CreateList from './CreateList.vue'

export default defineComponent({
  components: { List, draggable, CreateList },
  setup() {
    const route = useRoute()
    const { getBoard, board } = useBoards()
    const { lists, moveList } = useLists()
    const { cardsByListId, moveCard } = useCards()
    getBoard(route.params.id as string)

    const onDrag = (e: any) => {
      moveList(e.moved.oldIndex, e.moved.newIndex)
    }

    return {
      onDrag,
      lists,
      cardsByListId,
      board,
    }
  },
})
