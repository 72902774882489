
import { defineComponent, toRefs, computed, ref, nextTick } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useRouter, useRoute } from 'vue-router'
import useBoards from '@/store/useBoards'
import useLists from '@/store/useLists'
import useCards from '@/store/useCards'
import draggable from 'vuedraggable'

export default defineComponent({
  props: { list: Object },
  setup(props) {
    const creatingList = ref(false)
    const clickOutsideTarget = ref(null)
    const listName = ref('')
    const input = ref<any>(null)
    const { addList } = useLists()
    const route = useRoute()

    const setCreatingList = () => {
      creatingList.value = true
      nextTick(() => {
        input?.value?.focus()
      })
    }

    const submit = () => {
      addList(listName.value, Number(route.params.id))
      listName.value = ''
      creatingList.value = false
    }

    onClickOutside(clickOutsideTarget, (event) => (creatingList.value = false))
    return {
      clickOutsideTarget,
      input,
      creatingList,
      setCreatingList,
      listName,
      submit,
    }
  },
})
