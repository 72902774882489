
import { toRefs, defineComponent } from 'vue'
export default defineComponent({
  setup() {
    const truncate = (text: string, limit: number) => {
      if (text.length <= limit) return text
      return `${text.slice(0, limit)}...`
    }
    return {
      truncate,
    }
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: null,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
})
