
import { defineComponent, toRefs, computed, ref, nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import useBoards from '@/store/useBoards'
import useLists from '@/store/useLists'
import useCards from '@/store/useCards'
import draggable from 'vuedraggable'
import { onClickOutside } from '@vueuse/core'
import Card from './Card.vue'

export default defineComponent({
  components: { draggable, Card },
  props: { list: Object },
  setup(props) {
    const route = useRoute()
    const { lists } = useLists()
    const clickOutsideTarget = ref(null)
    const cardName = ref('')
    const { cardsByListId, reOrderCard, moveCard, createCard } = useCards()
    const isCreatingCard = ref(false)
    const input = ref<any>(null)

    const setCreatingCard = () => {
      isCreatingCard.value = true
      nextTick(() => {
        input?.value?.focus()
      })
    }

    const submitCard = () => {
      createCard(cardName.value, props?.list?.id)
      isCreatingCard.value = false
      cardName.value = ''
    }

    const onChange = (evt: any) => {
      if (evt.moved) {
        reOrderCard(props?.list?.id, evt.moved.oldIndex, evt.moved.newIndex)
      } else if (evt.added) {
        moveCard(props?.list?.id, evt.added.element.id, evt.added.newIndex)
        console.log(evt)
      }
    }

    onClickOutside(
      clickOutsideTarget,
      (event) => (isCreatingCard.value = false)
    )

    return {
      submitCard,
      clickOutsideTarget,
      setCreatingCard,
      onChange,
      cardName,
      lists,
      cardsByListId,
      isCreatingCard,
      input,
    }
  },
})
